@import '../../styles/properties.css';

.Caption {
  width: 10em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.Caption-prevBtn {
  margin-bottom: var(--spacing-small);
}

.Caption-nextBtn {
  margin-right: 0;
  margin-bottom: var(--spacing-small);
}

@media (min-width: 740px) {
  .Caption-prevBtn {
    float: left;
  }

  .Caption-nextBtn {
    float: right;
  }
}