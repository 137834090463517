@import '../styles/properties.css';

.Ident {
  display: block;
  margin: var(--spacing) 0 var(--spacing-huge);
  font-size: var(--spacing-large);
  transition: none;
}

.Ident-link {
  text-decoration: none;
  font-weight: bold;
  color: var(--brand-color);
}

.Ident-highlight {
  color: var(--text-color);
}
.Ident:hover .Ident-higherlight {
  color: var(--brand-color);
}

.Ident-space {
  display: none;
}
