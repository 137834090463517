@import '../styles/properties.css';

.Message {
  position: fixed;
  top: var(--spacing-small);
  opacity: .95;
  z-index: 100;
  padding-left: 0;
  padding-right: var(--spacing);
  max-width: calc(var(--wrapper-width) - var(--spacing));
  transition: var(--global-transition);
}

@media (max-width: 739px) {
  .Message {
    padding-right: var(--spacing-small);
  }
}

.Message-hidden {
  opacity: 0;
  visibility: hidden;
}
