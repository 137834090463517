@import '../styles/properties.css';

.Button {
  display: inline-block;
  font: inherit;
  color: var(--brand-color);
  text-align: center;
  margin-right: var(--spacing);
  margin-bottom: var(--spacing);
  padding: var(--spacing-tiny) var(--spacing);
  transition: var(--global-transition);
  border-radius: var(--global-radius);
  background-color: var(--background-color-light);
  border: 0;
  outline: 0;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2);
  position: relative;
}

.Button-noSpaceRight {
  margin-right: 0;
}

.Button:hover {
  color: var(--brand-color);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .2);
}

.Button:active {
  box-shadow: none;
}

.Button:disabled {
  opacity: .4;
}

.Button:disabled:hover {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2);
}

.Button-working::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  width: calc(var(--spacing) * 1.5);
  height: calc(var(--spacing) * 1.5);
  margin-left: calc(var(--spacing) * -1.5 / 2);
  background-color: var(--brand-color);
  border-radius: 100%;
  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out;
}

.Button-inverse {
  background-color: var(--background-color);
}

.Button-link {
  border: 0;
  background-color: transparent;
  padding: 0;
  text-decoration: underline;
  color: var(--brand-color);
  cursor: pointer;
  box-shadow: none;
}

.Button-link:hover {
  color: var(--text-color);
  box-shadow: none;
}

.Button-primary::after {
  content: '›';
  display: inline-block;
  padding-left: var(--spacing-tiny);
}

.Button-dropdown {
  border: 0;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  color: var(--text-color);
  margin-bottom: 0;
}

.Button-dropdown::after {
  content: '▼';
  font-size: var(--spacing-tiny);
  color: var(--text-color-light);
  display: inline-block;
  padding-left: var(--spacing-tiny);
  position: relative;
  top: calc(var(--spacing-tiny) / -3);
}

.Button-dropdown:hover {
  box-shadow: none;
}

.Button-dropdown:hover::after {
  color: var(--brand-color);
}

.Button-dropdownDropped::after {
  content: '▲';
}
