@import '../../styles/properties.css';

.Watch {
  display: block;
  width: 100%;
  line-height: 2em;
  border: 0;
  background-color: transparent;
  padding: var(--spacing-tiny) 0;
  margin: 0;
  font-size: var(--spacing-small);
  transition: var(--global-transition-fast);
  outline: 0;
  text-indent: -200em;
}

.Watch::before {
  display: block;
  text-align: center;
  text-indent: 0;
  position: absolute;
  width: 100%;
  color: var(--brand-color);
}

.Watch-taken {
  background-color: rgba(0, 0, 0, .1);
  text-indent: -200em;
}

.Watch-taken::before {
  content: '🙏';
  font-size: var(--font-size);
}

.Watch-own {
  background-color: var(--background-color-light);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2em);
  color: var(--brand-color);
}

.Watch-own::before {
  content: '🙏 Din vakt';
}

.Watch-own:hover {
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .2em);
}

.Watch-own:active {
  box-shadow: none;
}

.Watch-free {
  overflow: hidden;
}

.Watch-free::before {
  content: ' ';
  font-weight: bold;
}

.Watch-free:hover {
  background-color: rgba(0, 0, 0, .05);
}

.Watch-free:hover::before {
  content: '+';
}

@media (max-width: 739px) {
  .Watch-own::before {
    content: '🙏';
    font-size: var(--font-size);
  }
}