:root {
  /* --font-family-sans: 'Cairo', -apple-system, BlinkMacSystemFont, sans-serif;
  --font-family-brand: 'BebasNeue-Regular', -apple-system, BlinkMacSystemFont, sans-serif; */

  /* Colors */
  --background-color: #fff;
  --background-color-light: #fff8f0;
  --background-color-error: #fff0f0;
  --background-color-today: #fff0e0;
  --background-color-darkened: #fff8f0;
  --text-color: #333;
  --text-color-light: #666;
  --brand-color: #f42;
  --alt-color: #ffd080;
  --grid-border: #eee;
  --grid-border-light: #f8f8f8;

  /* Typography */
  --font-size: 18px;
  --font-size-large: 26px;
  --line-height: 28px;
  --line-height-small: calc(var(--line-height) * 0.5);

  --spacing: var(--line-height);
  --spacing-tiny: calc(var(--spacing) * 0.25);
  --spacing-small: calc(var(--spacing) * 0.5);
  --spacing-large: calc(var(--spacing) * 2);
  --spacing-huge: calc(var(--spacing) * 4);

  --wrapper-width: calc(var(--font-size) * 43);

  /* Some UI treatments */
  --global-radius: 3px !default;
  --global-transition: all 200ms ease-in-out !default;
  --global-transition-fast: all 100ms ease-in-out !default;
  --global-line-size: 3px;
}
