@import '../styles/properties.css';

.Wrapper {
  padding: 0 var(--spacing);
  margin: 0 auto;
  max-width: var(--wrapper-width);
}

@media (max-width: 739px) {
  .Wrapper {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
  }
}
