@import '../styles/properties.css';

.AccountMenu {
  position: absolute;
  right: var(--spacing);
  z-index: 2;
  width: auto;
  transition: var(--global-transition);
  opacity: 0;
  visibility: hidden;
}

.AccountMenu-visible {
  opacity: 1;
  visibility: visible;
}

.AccountMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: var(--global-radius);
  background-color: var(--background-color-light);
  border: 1px solid var(--alt-color);
}

.AccountMenu-item {
  margin: 0;
  padding: 0;
}

.AccountMenu-link {
  display: inline-block;
  line-height: 1;
  padding: calc(var(--spacing-small)/2) var(--spacing-small);
  margin: 0;
}
