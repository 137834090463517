@import '../styles/properties.css';

.Field {
  border: 1px solid var(--alt-color);
  background-color: var(--background-color-light);
  padding: var(--spacing-tiny);
  width: 100%;
  border-radius: var(--global-radius);
  outline: 0;
  position: relative;
}

.Field:focus {
  background-color: var(--background-color);
}

.Field-checkbox {
  display: inline-block;
  -webkit-appearance: none;
  border: 1px solid var(--alt-color);
  width: var(--spacing);
  height: var(--spacing);
  margin: 0 var(--spacing-tiny) 0 0;
  line-height: var(--spacing);
  vertical-align: bottom;
}

.Field-checkbox:checked::after {
  content: '✓';
  font-size: 120%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--spacing);
  height: var(--spacing);
  text-align: center;
}

.Field-inverse {
  background-color: var(--background-color);
}

.Field-inverse:focus {
  background-color: var(--background-color-light);
}
