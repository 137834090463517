@import '../styles/properties.css';

.Link-cta::before {
  content: '›';
  display: inline-block;
  padding-right: var(--spacing-tiny);
}

.Link-back::before {
  content: '‹';
  display: inline-block;
  padding-right: var(--spacing-tiny);
}

.Link-next::after {
  content: '›';
  display: inline-block;
  padding-left: var(--spacing-tiny);
}
