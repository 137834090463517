@import '../../styles/properties.css';

.calendar {
  border: 1px solid var(--grid-border);
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  position: relative;
  margin-bottom: var(--spacing);
}

.cell {
  text-align: center;
  border-left: 1px solid var(--grid-border);
  padding: 0;
  border-bottom: 1px solid var(--grid-border-light);
  position: relative;
}

.cell-header {
  padding: var(--spacing-tiny);
  border-bottom-color: var(--grid-border);
}

.cell-past {
  opacity: .5;
}

.cell-today {
  background-color: var(--background-color-today);
}

.cell-bottomAlign {
  vertical-align: bottom;
}

@media (max-width: 739px) {
  .cell {
    font-size: calc(var(--spacing-small) * .85);
  }

  .hideOnMobile {
    display: none;
  }
}

