@import './styles/properties.css';

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: var(--font-size);
  line-height: var(--line-height);
  margin: 0;
  overflow-y: scroll;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--brand-color);
  transition: var(--global-transition);
  outline: 0;
}

a:hover {
  color: var(--text-color);
}

a:focus {
  background-color: rgba(0, 0, 0, .04);
}

h1 {
  font-size: var(--font-size-large);
  color: var(--brand-color);
}

@media (max-width: 739px) {
  h1, h2 {
    font-size: calc(var(--font-size-large) * .9);
  }
}

h2 {
  margin-top: calc(var(--spacing) * 2 - var(--spacing-tiny));
  margin-bottom: var(--spacing-tiny);
}

small {
  font-size: var(--spacing-small);
  line-height: var(--line-height-small);
}

input {
  font-family: inherit;
  font-size: 1em;
}

hr {
  border: 0;
  border-top: var(--global-line-size) solid var(--brand-color);
  margin-top: var(--spacing-large);
  margin-bottom: calc(var(--spacing-large) - var(--global-line-size));
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
