@import '../styles/properties.css';

.Menu {
  margin: 0 var(--spacing);
  font-size: var(--spacing-small);
}

.Menu-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  list-style: none;
}

.Menu-item {
  margin: 0;
  padding: var(--spacing-tiny) 0 0;
}

.Menu-link {
  padding: 0 var(--spacing-tiny);
  position: relative;
}
