@import '../styles/properties.css';

.Content-intro {
  margin-bottom: var(--spacing-large);
}

.Content-intro strong {
  color: var(--brand-color);
}

@media (min-width: 740px) {
  .Content-intro {
    font-size: var(--font-size-large);
    line-height: calc(var(--spacing) * 1.5);
  }
}

.Content-working {
  margin-bottom: var(--spacing-huge);
}

.Content-working>* {
  opacity: .5;
}

.Content-working::before {
  content: ' ';
  display: block;
  position: fixed;
  left: 50%;
  top: var(--spacing);
  z-index: 2;
  width: calc(var(--spacing) * 1.5);
  height: calc(var(--spacing) * 1.5);
  margin: 100px auto;
  background-color: var(--brand-color);
  border-radius: 100%;
  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
