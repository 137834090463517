@import '../styles/properties.css';

.Box {
  display: block;
  padding: var(--spacing-small) var(--spacing);
  margin-bottom: var(--spacing);
}
.Box:last-child {
  margin-bottom: 0;
}

.Box-message {
  background-color: var(--background-color-light);
  border: 1px solid var(--alt-color);
}

.Box-light {
  background-color: var(--background-color-light);
}

.Box-error {
  background-color: var(--background-color-error);
  border: 1px solid var(--brand-color);
}
.Box-error::before {
  content: '⚠️';
  font-size: 1.5em;
  float: left;
  margin-right: var(--spacing);
}
